import React, { Suspense, useMemo } from "react";
import Loader from "../components/loader";
import WhatsApp from "../components/whatsapp";
function RoutesWrapper({ component }) {
  const Component = useMemo(() => {
    return React.lazy(component);
  }, [component]);
  return (
    <Suspense fallback={<Loader />}>
      <WhatsApp />
      <Component />
    </Suspense>
  );
}

export default RoutesWrapper;
