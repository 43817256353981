import React from "react";
import { Route, Routes } from "react-router-dom"; // Assuming you have a Layout component
import RoutesWrapper from "./RoutesWrapper";
import PageNotFound from "../pages/PageNotFound";
// Lazy load the HomePage component
const HomePage = () => import("../pages/Home");
const PricingPage = () => import("../pages/Pricing");
const AboutPage = () => import("../pages/About");
const AffiliatesPage = () => import("../pages/Affiliates");
const BlogsPage = () => import("../pages/Blogs");
const BookTrialPage = () => import("../pages/BookTrial");
const ContactUsPage = () => import("../pages/ContactUs");
const PrivacyPolicyPage = () => import("../pages/PrivacyPolicy");
const ResourceCenterPage = () => import("../pages/ResourceCenter");
const ShippingPage = () => import("../pages/Shipping");
const StudentCornerPage = () => import("../pages/StudentCorner");
const ProgramsPage = () => import("../pages/Programs");
const TermsAndConditionsPage = () => import("../pages/TermsAndConditions");
const APComponentPage = () => import("../pages/TestPrep/AP");
const CancelAndRefundPage = () => import("../pages/Cancel");
const ActEnglishPage = () => import("../pages/TestPrep/ACT/English");
const ActMathPage = () => import("../pages/TestPrep/ACT/Math");
const ActReadWritePage = () => import("../pages/TestPrep/ACT/ReadAndWrite");
const ActSciencePage = () => import("../pages/TestPrep/ACT/Science");
const SatPage = () => import("../pages/TestPrep/SAT/Sat");
const SatMathPage = () => import("../pages/TestPrep/SAT/SatMath");
const SatReadWritePage = () => import("../pages/TestPrep/SAT/SatReadAndWrite");
const USCurriculumOverviewPage = () =>
  import("../pages/AcademicTutoring/UsCurriculum");
const APStasticsPage = () => import("../pages/TestPrep/AP/Statistics");
const APBioPage = () => import("../pages/TestPrep/AP/Bio");
const APCalculasABPage = () => import("../pages/TestPrep/AP/CalculasAB");
const APCalculasBCPage = () => import("../pages/TestPrep/AP/CalculasBC");
const APChemistryCPage = () => import("../pages/TestPrep/AP/Chemistry");
const APCompSciencePage = () => import("../pages/TestPrep/AP/CompScience");
const APEngLitAndCommPage = () => import("../pages/TestPrep/AP/EngLitAndComm");
const APEnvSciencePage = () => import("../pages/TestPrep/AP/EnvScience");
const APMicroeconomicsPage = () =>
  import("../pages/TestPrep/AP/Microeconomics");
const APPhysics1And2Page = () => import("../pages/TestPrep/AP/Physics1And2");
const APPhysicsCPage = () => import("../pages/TestPrep/AP/PhysicsC");
const APPhysicsMechanicsPage = () =>
  import("../pages/TestPrep/AP/PhysicsMechanics");
const APPrecalculasPage = () => import("../pages/TestPrep/AP/Precalculas");
const ElementryschoolPage = () =>
  import("../pages/AcademicTutoring/UsCurriculum/Elementryschool");
const HighschoolPage = () =>
  import("../pages/AcademicTutoring/UsCurriculum/Highschool");
const MiddleschoollPage = () =>
  import("../pages/AcademicTutoring/UsCurriculum/Middleschool");
const PSATEightAnd9Page = () => import("../pages/TestPrep/PSAT/EightAnd9");
const PSATNmsqtPage = () => import("../pages/TestPrep/PSAT/Nmsqt");
const PSATTenPage = () => import("../pages/TestPrep/PSAT/Ten");
const OtherTestPrepSSATPage = () =>
  import("../pages/TestPrep/OtherTestPrep/SSAT");
const OtherTestPrepMathCountsPage = () =>
  import("../pages/TestPrep/OtherTestPrep/MathCounts");
const OtherTestPrepAMC8Page = () =>
  import("../pages/TestPrep/OtherTestPrep/AMC8");
const OtherTestPrepAMC10And12Page = () =>
  import("../pages/TestPrep/OtherTestPrep/AMC10And12");
const OtherTestPrepTerranova2Page = () =>
  import("../pages/TestPrep/OtherTestPrep/Terranova");
const OtherTestPrepSTARPage = () =>
  import("../pages/TestPrep/OtherTestPrep/STAR");
const OtherTestPrepParccPage = () =>
  import("../pages/TestPrep/OtherTestPrep/Parcc");
const OtherTestPrepRegentsPage = () =>
  import("../pages/TestPrep/OtherTestPrep/Regents");
const OtherTestPrepFSAPage = () =>
  import("../pages/TestPrep/OtherTestPrep/FSA");
const OtherTestPrepMCATPage = () =>
  import("../pages/TestPrep/OtherTestPrep/MCAT");
const OtherTestPrepCAASPPPage = () =>
  import("../pages/TestPrep/OtherTestPrep/CaaSpp");
const OtherTestPrepHSPTage = () =>
  import("../pages/TestPrep/OtherTestPrep/HSPT");
const AUNaplanPage = () =>
  import("../pages/AcademicTutoring/AuCurriculum/Naplan");
const AUPrimary2To6Page = () =>
  import("../pages/AcademicTutoring/AuCurriculum/Primary2To6");
const AUSecondary7To10Page = () =>
  import("../pages/AcademicTutoring/AuCurriculum/Secondary7To10");
const AUSenior11To12Page = () =>
  import("../pages/AcademicTutoring/AuCurriculum/Senior11To12");
const APMacroeconomicsPage = () =>
  import("../pages/TestPrep/AP/Macroeconomics");
const MLSKeyStage1TutoringPage = () =>
  import("../pages/AcademicTutoring/UKCurriculam/KeyStage1");
const MLSKeyStage2TutoringPage = () =>
  import("../pages/AcademicTutoring/UKCurriculam/KeyStage2");
const MLSKeyStage3TutoringPage = () =>
  import("../pages/AcademicTutoring/UKCurriculam/KeyStage3");
const MLSKeyStage4TutoringPage = () =>
  import("../pages/AcademicTutoring/UKCurriculam/KeyStage4");
const MLSKeyStage5TutoringPage = () =>
  import("../pages/AcademicTutoring/UKCurriculam/KeyStage5");
const MLSCSharpProgramminPage = () => import("../pages/ITCourses/CSharp");
const MLSCPPProgrammingPage = () => import("../pages/ITCourses/CPlus");
const MLSWebDevelopmentPage = () => import("../pages/ITCourses/Html");
const MLSJavaProgrammingPage = () => import("../pages/ITCourses/Java");
const MLSPythonProgrammingPage = () => import("../pages/ITCourses/Python");
const ASALevelTutoringPage = () =>
  import("../pages/AcademicTutoring/ALevelCurriculum");
const IBStatisticsTutoringPage = () =>
  import("../pages/AcademicTutoring/IBStaticsCurriculum");
const IGCSETutoringPage = () =>
  import("../pages/AcademicTutoring/IGCSECurriculum");
const LinearAlgebraTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Algebra");

const CollegeBiologyTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Biology");
const AnatomyPhysiologyTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Anatomy");
const MultivariableCalculusTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Calculas");

const CollegeChemistryTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Chemistry");
const DifferentialEquationsTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/DiffEq");
const DiscreteMathematicsTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Discrete");
const CollegeEnglishTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/English");
const CollegeGeneticsTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Genetics");
const CollegeMathTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Math");
const MolecularBiologyTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Molecular");
const OrganicChemistryTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/OrganicChemistry");
const CollegePhysicsTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Physics");
const CollegeStatisticsTutoringPage = () =>
  import("../pages/AcademicTutoring/CollegeCourses/Statistics");

function PublicRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<RoutesWrapper component={HomePage} />} />
      <Route
        path="/pricing"
        exact
        element={<RoutesWrapper component={PricingPage} />}
      />
      <Route
        path="/about"
        exact
        element={<RoutesWrapper component={AboutPage} />}
      />
      <Route
        path="/affiliats"
        exact
        element={<RoutesWrapper component={AffiliatesPage} />}
      />
      <Route
        path="/blogs"
        exact
        element={<RoutesWrapper component={BlogsPage} />}
      />
      <Route
        path="/book-trial"
        exact
        element={<RoutesWrapper component={BookTrialPage} />}
      />
      <Route
        path="/contact-us"
        exact
        element={<RoutesWrapper component={ContactUsPage} />}
      />
      <Route
        path="/privacy-policy"
        exact
        element={<RoutesWrapper component={PrivacyPolicyPage} />}
      />
      <Route
        path="/resource-center"
        exact
        element={<RoutesWrapper component={ResourceCenterPage} />}
      />
      <Route
        path="/shipping"
        exact
        element={<RoutesWrapper component={ShippingPage} />}
      />
      <Route
        path="/student-corner"
        exact
        element={<RoutesWrapper component={StudentCornerPage} />}
      />
      <Route
        path="/test-prep/act/act-english"
        exact
        element={<RoutesWrapper component={ActEnglishPage} />}
      />
      <Route
        path="/test-prep/act/act-math"
        exact
        element={<RoutesWrapper component={ActMathPage} />}
      />
      <Route
        path="/test-prep/act/act-read-write"
        exact
        element={<RoutesWrapper component={ActReadWritePage} />}
      />
      <Route
        path="/test-prep/act/act-science"
        exact
        element={<RoutesWrapper component={ActSciencePage} />}
      />
      <Route
        path="/test-prep/sat/sat"
        exact
        element={<RoutesWrapper component={SatPage} />}
      />
      <Route
        path="/test-prep/sat/sat-math"
        exact
        element={<RoutesWrapper component={SatMathPage} />}
      />
      <Route
        path="/test-prep/sat/sat-read-write"
        exact
        element={<RoutesWrapper component={SatReadWritePage} />}
      />
      <Route
        path="/programs"
        exact
        element={<RoutesWrapper component={ProgramsPage} />}
      />
      <Route
        path="/terms"
        exact
        element={<RoutesWrapper component={TermsAndConditionsPage} />}
      />
      <Route
        path="/ap-courses/ap-precalculus"
        exact
        element={<RoutesWrapper component={APPrecalculasPage} />}
      />
      {/* <Route
        path="/ap-courses/ap-calculus-ab"
        exact
        element={<RoutesWrapper component={APComponentPage} />}
      /> */}
      <Route
        path="/ap-courses/ap-statistics"
        exact
        element={<RoutesWrapper component={APStasticsPage} />}
      />
      <Route
        path="/academic-tutoring/us-curriculum/elementary-school"
        exact
        element={<RoutesWrapper component={ElementryschoolPage} />}
      />
      <Route
        path="/academic-tutoring/us-curriculum/middle-school"
        exact
        element={<RoutesWrapper component={MiddleschoollPage} />}
      />
      <Route
        path="/academic-tutoring/us-curriculum/high-school"
        exact
        element={<RoutesWrapper component={HighschoolPage} />}
      />
      <Route
        path="/ap-courses/ap-biology"
        exact
        element={<RoutesWrapper component={APBioPage} />}
      />
      <Route
        path="/ap-courses/ap-calculus-ab"
        exact
        element={<RoutesWrapper component={APCalculasABPage} />}
      />
      <Route
        path="/ap-courses/ap-calculus-bc"
        exact
        element={<RoutesWrapper component={APCalculasBCPage} />}
      />
      <Route
        path="/ap-courses/ap-physics-1-2"
        exact
        element={<RoutesWrapper component={APPhysics1And2Page} />}
      />
      <Route
        path="/ap-courses/ap-physics-c-electricity-magnetism"
        exact
        element={<RoutesWrapper component={APPhysicsCPage} />}
      />
      <Route
        path="/ap-courses/ap-physics-c-mechanics"
        exact
        element={<RoutesWrapper component={APPhysicsMechanicsPage} />}
      />
      <Route
        path="/ap-courses/ap-chemistry"
        exact
        element={<RoutesWrapper component={APChemistryCPage} />}
      />
      <Route
        path="/ap-courses/ap-environmental-science"
        exact
        element={<RoutesWrapper component={APEnvSciencePage} />}
      />
      <Route
        path="/ap-courses/ap-computer-science-a"
        exact
        element={<RoutesWrapper component={APCompSciencePage} />}
      />
      <Route
        path="/ap-courses/ap-english-literature-composition"
        exact
        element={<RoutesWrapper component={APEngLitAndCommPage} />}
      />
      <Route
        path="/ap-courses/ap-microeconomics"
        exact
        element={<RoutesWrapper component={APMicroeconomicsPage} />}
      />
      <Route
        path="/ap-courses/ap-macroeconomics"
        exact
        element={<RoutesWrapper component={APMacroeconomicsPage} />}
      />
      <Route
        path="/cancel"
        exact
        element={<RoutesWrapper component={CancelAndRefundPage} />}
      />
      <Route
        path="/academic-tutoring/us-curriculum"
        exact
        element={<RoutesWrapper component={USCurriculumOverviewPage} />}
      />
      <Route
        path="/test-prep/psat/psat-8-9"
        exact
        element={<RoutesWrapper component={PSATEightAnd9Page} />}
      />
      <Route
        path="/test-prep/psat/psat-10"
        exact
        element={<RoutesWrapper component={PSATTenPage} />}
      />
      <Route
        path="/test-prep/psat/psat-nmsqt"
        exact
        element={<RoutesWrapper component={PSATNmsqtPage} />}
      />
      <Route
        path="/test-prep/mathcounts"
        exact
        element={<RoutesWrapper component={OtherTestPrepMathCountsPage} />}
      />
      <Route
        path="/test-prep/ssat"
        exact
        element={<RoutesWrapper component={OtherTestPrepSSATPage} />}
      />
      <Route
        path="/test-prep/amc-8"
        exact
        element={<RoutesWrapper component={OtherTestPrepAMC8Page} />}
      />
      <Route
        path="/test-prep/amc-10"
        exact
        element={<RoutesWrapper component={OtherTestPrepAMC10And12Page} />}
      />
      <Route
        path="/test-prep/terranova"
        exact
        element={<RoutesWrapper component={OtherTestPrepTerranova2Page} />}
      />
      <Route
        path="/test-prep/parcc"
        exact
        element={<RoutesWrapper component={OtherTestPrepParccPage} />}
      />
      <Route
        path="/test-prep/hspt"
        exact
        element={<RoutesWrapper component={OtherTestPrepHSPTage} />}
      />
      <Route
        path="/test-prep/regents"
        exact
        element={<RoutesWrapper component={OtherTestPrepRegentsPage} />}
      />
      <Route
        path="/test-prep/fsa"
        exact
        element={<RoutesWrapper component={OtherTestPrepFSAPage} />}
      />
      <Route
        path="/test-prep/staar"
        exact
        element={<RoutesWrapper component={OtherTestPrepSTARPage} />}
      />
      <Route
        path="/test-prep/caaspp"
        exact
        element={<RoutesWrapper component={OtherTestPrepCAASPPPage} />}
      />
      <Route
        path="/test-prep/mcat"
        exact
        element={<RoutesWrapper component={OtherTestPrepMCATPage} />}
      />
      <Route
        path="/academic-tutoring/au-curriculum/primary"
        exact
        element={<RoutesWrapper component={AUPrimary2To6Page} />}
      />
      <Route
        path="/academic-tutoring/au-curriculum/secondary"
        exact
        element={<RoutesWrapper component={AUSecondary7To10Page} />}
      />
      <Route
        path="/academic-tutoring/au-curriculum/senior"
        exact
        element={<RoutesWrapper component={AUSenior11To12Page} />}
      />
      <Route
        path="/academic-tutoring/au-curriculum/naplan"
        exact
        element={<RoutesWrapper component={AUNaplanPage} />}
      />
      <Route
        path="/academic-tutoring/uk-curriculum/key-stage-1"
        exact
        element={<RoutesWrapper component={MLSKeyStage1TutoringPage} />}
      />
      <Route
        path="/academic-tutoring/uk-curriculum/key-stage-2"
        exact
        element={<RoutesWrapper component={MLSKeyStage2TutoringPage} />}
      />
      <Route
        path="/academic-tutoring/uk-curriculum/key-stage-3"
        exact
        element={<RoutesWrapper component={MLSKeyStage3TutoringPage} />}
      />
      <Route
        path="/academic-tutoring/uk-curriculum/key-stage-4"
        exact
        element={<RoutesWrapper component={MLSKeyStage4TutoringPage} />}
      />
      <Route
        path="/academic-tutoring/uk-curriculum/key-stage-5"
        exact
        element={<RoutesWrapper component={MLSKeyStage5TutoringPage} />}
      />
      <Route
        path="/it-courses/csharp-programming"
        exact
        element={<RoutesWrapper component={MLSCSharpProgramminPage} />}
      />
      <Route
        path="/it-courses/cplusplus-programming"
        exact
        element={<RoutesWrapper component={MLSCPPProgrammingPage} />}
      />
      <Route
        path="/it-courses/html-web-development"
        exact
        element={<RoutesWrapper component={MLSWebDevelopmentPage} />}
      />
      <Route
        path="/it-courses/java"
        exact
        element={<RoutesWrapper component={MLSJavaProgrammingPage} />}
      />
      <Route
        path="/it-courses/python"
        exact
        element={<RoutesWrapper component={MLSPythonProgrammingPage} />}
      />
      <Route
        path="/academic-tutoring/ib-curriculum"
        exact
        element={<RoutesWrapper component={IBStatisticsTutoringPage} />}
      />
      <Route
        path="/academic-tutoring/igcse-curriculum"
        exact
        element={<RoutesWrapper component={IGCSETutoringPage} />}
      />
      <Route
        path="/academic-tutoring/as-a-level-curriculum"
        exact
        element={<RoutesWrapper component={ASALevelTutoringPage} />}
      />
      <Route
        path="/college-courses/college-math"
        exact
        element={<RoutesWrapper component={CollegeMathTutoringPage} />}
      />
      <Route
        path="/college-courses/differential-equations"
        exact
        element={
          <RoutesWrapper component={DifferentialEquationsTutoringPage} />
        }
      />
      <Route
        path="/college-courses/differential-equations"
        exact
        element={
          <RoutesWrapper component={DifferentialEquationsTutoringPage} />
        }
      />
      <Route
        path="/college-courses/discrete-math"
        exact
        element={<RoutesWrapper component={DiscreteMathematicsTutoringPage} />}
      />
      <Route
        path="/college-courses/college-statistics"
        exact
        element={<RoutesWrapper component={CollegeStatisticsTutoringPage} />}
      />
      <Route
        path="/college-courses/multivariable-calculus"
        exact
        element={
          <RoutesWrapper component={MultivariableCalculusTutoringPage} />
        }
      />
      <Route
        path="/college-courses/linear-algebra"
        exact
        element={<RoutesWrapper component={LinearAlgebraTutoringPage} />}
      />
      <Route
        path="/college-courses/college-biology"
        exact
        element={<RoutesWrapper component={CollegeBiologyTutoringPage} />}
      />
      <Route
        path="/college-courses/molecular-biology"
        exact
        element={<RoutesWrapper component={MolecularBiologyTutoringPage} />}
      />
      <Route
        path="/college-courses/college-genetics"
        exact
        element={<RoutesWrapper component={CollegeGeneticsTutoringPage} />}
      />
      <Route
        path="/college-courses/anatomy-physiology"
        exact
        element={<RoutesWrapper component={AnatomyPhysiologyTutoringPage} />}
      />
      <Route
        path="/college-courses/college-chemistry"
        exact
        element={<RoutesWrapper component={CollegeChemistryTutoringPage} />}
      />
      <Route
        path="/college-courses/organic-chemistry"
        exact
        element={<RoutesWrapper component={OrganicChemistryTutoringPage} />}
      />
      <Route
        path="/college-courses/college-english"
        exact
        element={<RoutesWrapper component={CollegeEnglishTutoringPage} />}
      />
      <Route
        path="/college-courses/college-physics"
        exact
        element={<RoutesWrapper component={CollegePhysicsTutoringPage} />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default PublicRoutes;
