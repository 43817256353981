import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom"; // assuming you're using react-router

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-background text-text px-4">
      <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={handleBackHome}
              className="bg-primary hover:bg-secondary border-none"
            >
              Back Home
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default PageNotFound;
