import React from "react";
import { Spin } from "antd";

const content = <div className="loader" />;

const Loader = () => (
  <div className="loader flex justify-center items-center">
    <Spin tip="Loading" className="loader-icon">
      {content}
    </Spin>
  </div>
);

export default Loader;
