import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

export default function AppFooter() {
  return (
    <div>
      <hr />
      <div className="flex flex-row flex-wrap color-background  text-color justify-between container sm:flex-col sm:items-center sm:flex-nowrap">
        <div className="flex flex-col sm:items-center my-2">
          <a href="/">
            <img src={"/static/logo.png"} alt="mls logo" className="logo" />
          </a>
          <div className="flex flex-row">
            <div className="ms-1 p-1">
              <a
                href="https://www.facebook.com/mlsclasses?mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook />
              </a>
            </div>
            <div className="ms-1 p-1">
              <a
                href="https://www.instagram.com/mlsclasses_usa?igsh=djFpaHZhZm1zdHBl&utm_source=qr"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
            <div className="ms-1 p-1">
              <a
                href="https://www.linkedin.com/company/online-tutor-usa/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin />
              </a>
            </div>
            <div className="ms-1 p-1">
              <a
                href="https://www.youtube.com/@mlsclasses8293?si=KBojcjPosvKjfwjH"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube />
              </a>
            </div>
            <div className="ms-1 p-1">
              <a
                href="https://wa.me/message/XMS5KMWBGQZLG1"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>

        <div className="flex items-start flex-col sm:items-center my-2">
          <h2 className="text-lg font-bold">Company</h2>
          <div>
            <a href="/about">About</a>
          </div>
          <div>
            <a href="/shipping">Shipping</a>
          </div>
        </div>
        <div className="flex items-start flex-col sm:items-center my-2">
          <h2 className="text-lg font-bold">Reach us</h2>
          <div className="">
            <a href="mailto:ritik@mlsclasses.com">ritik@mlsclasses.com</a>
          </div>
          <div>
            <a href="/contact-us">Contact us</a>
          </div>
        </div>

        <div className="flex items-start flex-col sm:items-center my-2">
          <div className="text-lg font-bold">Other Resources</div>
          <div>
            <a href="/blogs">Blogs</a>
          </div>
          <div>
            <a href="/resource-center">Resources Center</a>
          </div>
          <div>
            <a href="/affiliats">Affiliates</a>
          </div>
        </div>
      </div>
      <div className="bg-black w-100 p-4 flex flex-wrap  justify-between  uppercase text-xs container flex-row sm:flex-col sm:items-center sm:flex-nowrap ">
        <ul className="flex sm:my-2">
          <li className="ms-2">
            <a href="/terms" className="text-white">
              Terms &amp; Conditions
            </a>
          </li>
          <li className="ms-2">
            <a href="/privacy-policy" className="text-white">
              Privacy Policy
            </a>
          </li>
          <li className="ms-2">
            <a href="/cancel" className="text-white">
              Cancellation & Refund
            </a>
          </li>
        </ul>
        <div class="flex sm:my-2">
          <span className="ms-2"> © 2024</span>
          <a
            href="https://www.mlsclasses.com/"
            target="_blank"
            rel="noreferrer"
            className="text-golden ms-2"
          >
            MLS Classes: Interactive 1:1 Online Classes: Digital SAT, ACT, AP,
            Common Core, IB & IGCSE.
          </a>
          <span className="ms-1">All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
}
