import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import PublicRoutes from "./routes/PublicRoutes";

import LayoutTopBottom from "./layout/LayoutTopBottom";

const themes = ["light", "dark", "custom1", "custom2"];

function App() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    const currentIndex = themes.indexOf(theme);
    const nextIndex = (currentIndex + 1) % themes.length;
    setTheme(themes[nextIndex]);
  };

  return (
    <div className="App">
      <LayoutTopBottom>
        <Routes>
          <Route path="/*" element={<PublicRoutes />} />
        </Routes>
      </LayoutTopBottom>
    </div>
  );
}

export default App;
