import "./index.css";

const ChatWithUs = () => {
  window.open("https://wa.me/message/XMS5KMWBGQZLG1", "_blank");
};
const WhatsApp = () => {
  return (
    <div className="whatsapp_icon">
      <img
        src={"/static/whatsapp.png"}
        id="whatsapp_icon"
        alt="whatsapp icon"
        onClick={ChatWithUs}
      />
    </div>
  );
};
export default WhatsApp;
